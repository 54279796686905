<template>
  <div>
    <div class="innerPage A4Container flexCloumnCenter">
      <div class="trans">
        <div class="title flexCenter" style="width: 100%;height: 40px;">
          {{ year }} {{info.xx_name}} 学生毕业实习审批表
        </div>
        <div class="title flexEnd" style="width: 100%;height: 40px;">
          上报时间：<span style="min-width: 100px;">{{info.cdate||'-'}}</span>
        </div>
        <div class="form" style="height: calc( 100% - 40px );">
          <el-row class="borderR">
            <el-col :span="3" class="flexCenter cell cell50">班级</el-col>
            <el-col :span="3" class="flexCenter cell cell50">专业</el-col>
            <el-col :span="3" class="flexCenter cell cell50">人数</el-col>
            <el-col :span="3" class="flexCenter cell cell50">实习起止时间</el-col>
            <el-col :span="3" class="flexCenter cell cell50">班主任</el-col>
            <el-col :span="3" class="flexCenter cell cell50">联系电话</el-col>
            <el-col :span="3" class="flexCenter cell cell50">指导老师</el-col>
            <el-col :span="3" class="flexCenter cell cell50">联系电话</el-col>
          </el-row>
          <el-row class="borderR">
            <el-col :span="3" class="flexCenter cell cell50">{{info.bj_name||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.zy_name||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.stutotal||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.range||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.bzr_name||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.bzr_phone||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.zd_name||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.zd_phone||'-'}}</el-col>
          </el-row>
          <el-row class="borderR">
            <el-col :span="3" class="flexCenter cell cell50">实习单位名称</el-col>
            <el-col :span="3" class="flexCenter cell cell50">性质</el-col>
            <el-col :span="3" class="flexCenter cell cell50">部门</el-col>
            <el-col :span="3" class="flexCenter cell cell50">岗位</el-col>
            <el-col :span="3" class="flexCenter cell cell50">实习人数</el-col>
            <el-col :span="3" class="flexCenter cell cell50" style="text-align: center;line-height:unset;">实习待遇<br/>(元/月)</el-col>
            <el-col :span="3" class="flexCenter cell cell50">单位联系人</el-col>
            <el-col :span="3" class="flexCenter cell cell50">联系电话</el-col>
          </el-row>
          <el-row class="borderR">
            <el-col :span="3" class="flexCenter cell cell50">{{info.firm_name||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.firm_info||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.firm_dept||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.firm_job||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.stusx||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.firm_salary||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.firm_lxr||'-'}}</el-col>
            <el-col :span="3" class="flexCenter cell cell50">{{info.firm_tel||'-'}}</el-col>
          </el-row>
          <el-row class="borderR">
            <el-col :span="3" class="flexCenter cell cell50">备注</el-col>
            <el-col :span="21" class="flexCenter cell cell50">{{info.remark||'-'}}</el-col>
          </el-row>
          <el-row class="borderR borderB flexStretch" style="height: calc( 100% - 300px );">

            <el-col :span="3" class="flexCenter cell cell50" style="text-align: center">学 校<br/>意 见</el-col>
            <el-col :span="9" class="flexCenter cell" style="padding: 10px;">
              <div :id="'divCntnrA_'+idx"
                   style="width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;">
                <div :id="'resizeDivA_'+idx" style="width: 100%;word-break: break-word">
                  {{ info.visit_summary || '-' }}
                </div>

                <div class="flexBetween" style="position: absolute;bottom: 0;width: calc( 100% - 20px );line-height: 50px;">
                  <div>校长（签名）: </div>
                  <div> &nbsp;&nbsp;&nbsp;&nbsp;年 &nbsp;&nbsp;&nbsp;&nbsp; 月 &nbsp;&nbsp;&nbsp;&nbsp; 日（盖章）</div>
                </div>
              </div>
            </el-col>
            <el-col :span="3" class="flexCenter cell cell50" style="text-align: center">审 批<br/>意 见</el-col>
            <el-col :span="9" class="cell" style="padding: 10px;">


              <div :id="'divCntnrB_'+idx"
                   style="width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;">
                <div :id="'resizeDivB_'+idx" style="width: 100%;word-break: break-word">
                  {{ info.visit_summary || '-' }}
                </div>

                <div class="flexBetween" style="position: absolute;bottom: 0;width: calc( 100% - 20px );line-height: 50px;">
                  <div>审批人（签名）: </div>
                  <div> &nbsp;&nbsp;&nbsp;&nbsp;年 &nbsp;&nbsp;&nbsp;&nbsp; 月 &nbsp;&nbsp;&nbsp;&nbsp; 日（盖章）</div>
                </div>
              </div>


            </el-col>
          </el-row>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'roll',
  data() {
    return {
      dataover: false,
      userid: '',
      year:'',
    }
  },
  props: {
    info: {
      type: Object,
      default: {}
    },
    idx: {
      type: Number,
      default: 1
    },
  },
  mounted() {
    let _this = this
    this.year = new Date().getFullYear()
    if (_this.info.username) {
      _this.$nextTick(() => {
        _this.resizeA()
      })
    }

  },
  methods: {
    resizeA() {
      let divHeight = document.getElementById(`divCntnrA_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
      console.log('a', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 5) {
        document.getElementById(`resizeDivA_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
        i++
      }
    },
  }
}
</script>

<style lang="less" type="text/less">
.stuRolls .ant-col {
  display: flex;
}
</style>
<style lang="less" type="text/less" scoped>
.innerPage {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .cell {
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    word-break: break-all;

    min-height: 30px;
    font-size: 14px;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
  }

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: 100%;
    height: calc(100% - 50px);
    padding: 0;
    border: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

  }
}

.trans {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 40px;
  transform: rotate(-90deg);

}
</style>
